@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.gallery {
  column-gap: 2px;
  column-count: 1;
  p {
    width: 100%;
    height: auto;
    margin: 2px;
    img {
      width: 100%;
    }
  }
}

.home {
  position: relative;
  text-align: center;
  img {
    min-width: 100%;
    height: auto;
  }
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3rem;
  }
}

@include media-breakpoint-up(sm) {
  .gallery {
    column-count: 2;
  }
}

@include media-breakpoint-up(lg) {
  .gallery {
    column-count: 3;
  }
}

@include media-breakpoint-up(xl) {
  .gallery {
    column-count: 4;
  }
}


@import "~bootstrap/scss/bootstrap";
